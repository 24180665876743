<template>
  <div class="number-grow-warp">
    <span
      ref="numberGrow"
      :data-time="time"
      class="number-grow"
      :data-value="value"
      >0</span
    >
    <span class="plus">+</span>
  </div>
</template>

<script>
export default {
  name: "NumberGrow",
  props: {
    value: {
      type: Number,
      default() {
        return 720;
      },
    },
    time: {
      type: Number,
      default() {
        return 2;
      },
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.numberGrow(this.$refs.numberGrow);
  },
  methods: {
    numberGrow(ele) {
    //   let step = parseInt((this.value * 100) / (this.time * 1000));
      let current = 0;
      let start = 0;
      let t = setInterval(() => {
        // start += step;
        start += 1;
        if (start > this.value) {
          clearInterval(t);
          start = this.value;
          t = null;
        }
        if (start == 0) {
          start = this.value;
          clearInterval(t);
        }
        if (this.value === 0) {
          return;
        }
        current = start;
        ele.innerHTML = current
          .toString()
          .replace(/(\d)(?=(?:\d{3}[+]?)+$)/g, "$1,");
      }, this.time * 100);
    },
  },
};
</script>

<style scoped>

.number-grow-warp {
  display: flex;
  flex-direction: row;
  align-items: center;  /* 这里的 align-items 属性可能影响加号的垂直位置 */
}
.plus {
  margin-left: 5px;
  font-size: 30px;
  color: #0077ff;
  font-weight: bold;
  transform: translateY(-5px);
}
.number-grow-warp {
  transform: translateZ(0);
}
.number-grow {
  display: block;
}
</style>