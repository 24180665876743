import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import ElementUI from 'element-ui'
Vue.config.productionTip = false


import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import VueLazyload from 'vue-lazyload';

Vue.use(VueLazyload, {
  loading: require('@/assets/logo.png'),
  error: require(`@/assets/logo.png`),
  // 懒加载配置项
  lazyComponent: true,
  observer: true
});
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
export default router
new Vue({
  router,
  render: h => h(App),
  template: '<App/>'
}).$mount('#app')
