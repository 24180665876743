<template>
  <div id="NbSb">
    <h1>GPT AUTO Article -- GPT全自动发布文章</h1>
    SEO 升权神器！智能创作<br />
    全自动化撰写发布/ 标题 /内容，自动撰写自动发布<br />

    <acnm>
      每次写文章会在已经写了的话题中随机抽取一个话题进行撰写发布，话题可以是关键词（gpt自动按照关键词进行扩展），也可以是详细的内容。
      <br />比如3个话题100篇，在每次撰写一篇的时候自动在这3个话题中随机选取一篇进行撰写发布
    </acnm>
    <div>
      <p>本次更新优化gpt对关键词的读取</p>
      <strong> 话题 / 关键词 ： </strong>
      <br />

      <el-input type="textarea"
                :autosize="{ minRows: 4 }"
                placeholder="请输入需要发布的 话题 / 关键词 每行一个可输入多行 / 一行输入多个关键词则是这篇文章需要的这些关键词"
                v-model="theme"
                style="width: 50%">
      </el-input>
    </div>
    <div>
      话题随机顺序发布 ：
      <template>
        <el-select v-model="random_yes"
                   placeholder="请选择">
          <el-option v-for="item in random_or_no"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
      </template>
    </div>
    <br />
    文章篇数
    <el-slider v-model="count"
               show-input
               :max="parseInt(number)"
               style="width: 50%">
    </el-slider>
    <br />

    <div>
      博客程序 ：
      <template>
        <el-select v-model="blog_type"
                   placeholder="请选择">
          <el-option v-for="item in options"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
      </template>
    </div>

    <div v-if="blog_type === 'eyou' || blog_type == 'diguo'">
      <div>
        输入eyou的发文接口:
        <el-input placeholder="发文接口"
                  v-model="send_api"
                  style="width: 50%">
        </el-input>
      </div>
    </div>

    <div>
      配图类型：
      <template>
        <el-select v-model="blog_img"
                   placeholder="请选择">
          <el-option v-for="item in blog_img_type"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
      </template>
    </div>

    <div>
      发布状态：
      <template>
        <el-select v-model="log_Status"
                   placeholder="请选择">
          <el-option v-for="item in log_Status_list"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
      </template>
    </div>
    <div>
      文章分类ID
      <el-input placeholder="分类ID"
                v-model="fl_id"
                style="width: 50%">
      </el-input>
      <el-popover placement="bottom"
                  title="目前支持"
                  width="300"
                  trigger="click"
                  content="
                                typecho,wordpress,
                                zblog,
                                emlog,魔方财务,eyou,帝国 【易优 帝国 可以填写 栏目名称或id】">
        <i class="el-icon-question"
           slot="reference"
           style="font-size: 30px"></i>
      </el-popover>
    </div>

    <div>
      标签ID：
      <el-input type="textarea"
                placeholder="请输入标签id，多个id请使用英文逗号分割"
                v-model="tag_id"
                style="width: 30%">
      </el-input>
      <el-popover placement="bottom"
                  title="目前支持"
                  width="300"
                  trigger="click"
                  content="
                                typecho ,wordpress,eyou,帝国;
                                【eyou，帝国 的不需要填写id，直接填写tag名称即可，例如：人工智能,未来  这样是2个tag，其他博客填写tag的id】
                                ">
        <i class="el-icon-question"
           slot="reference"
           style="font-size: 30px"></i>
      </el-popover>
      <br />
      <el-button class="login-btn"
                 @click="get_tag()">查询博客标签ID 和 分类ID</el-button>
      <!-- <el-input placeholder="标签id：" v-model="tag_id" style="width:50%"> </el-input> -->
    </div>

    <div>
      定时执行
      <el-time-picker v-model="def_time"
                      placeholder="任意时间点">
      </el-time-picker>

      <!-- <el-input placeholder="数据库地址" v-model="def_time" style="width:80%"> </el-input> -->
    </div>
    <div>
      自定义api接口url
      <el-input placeholder="默认使用官方接口 https://api.openai.com/v1"
                v-model="url"
                style="width: 50%">
      </el-input>
    </div>
    <div>
      key
      <el-input placeholder="使用自己的key，多个key请使用 【空格】隔开，例如key1 key2 key3"
                v-model="key"
                style="width: 50%">
      </el-input>
    </div>
    <div v-if="this.blog_type != 'eyou' && this.blog_type != 'diguo'">
      <div>
        数据库地址
        <el-input placeholder="数据库地址"
                  v-model="data_host"
                  style="width: 50%">
        </el-input>
      </div>
      <div>
        数据库端口
        <el-input placeholder="数据库端口"
                  v-model="data_prot"
                  style="width: 50%">
        </el-input>
      </div>
      <div>
        数据库名
        <el-input placeholder="数据库名"
                  v-model="data_nbsb"
                  style="width: 50%">
        </el-input>
      </div>
      <div>
        数据库用户名
        <el-input placeholder="数据库用户名"
                  v-model="data_user"
                  style="width: 50%">
        </el-input>
      </div>
      <div>
        数据库密码
        <el-input placeholder="数据库密码"
                  v-model="data_pass"
                  style="width: 50%">
        </el-input>
      </div>
      <el-button class="login-btn"
                 @click="test_conn()">测试数据库是否连接</el-button>
    </div>

    <el-button class="login-btn"
               @click="send()">提交</el-button>
    <br />
    <h9 style="font-size: 12px">最近一次更新时间：2023/10/22 22：20 |
      <span style="color: rgb(0, 174, 255)">官网：http://auto.bctweb.cn</span>
    </h9>
  </div>
</template>

<script>
import { GPTAPI } from "@/config";
const axios = require("axios");
export default {
  el: "#NbSb",
  data () {
    return {
      send_api: "",
      number: 10000,
      data_nbsb: "blog",
      tag_id: "",
      url: "",
      key: "",
      data_user: "root",
      data_pass: "123456",
      data_host: "localhost",
      data_prot: "3306",
      log_Status: true, //默认发布
      def_time: 0,
      fl_id: "1",
      count: 1,
      theme: "",

      blog_type: "ty",
      random_yes: "yes",
      random_or_no: [
        {
          value: "yes",
          label: "yes",
        },
        {
          value: "no",
          label: "no",
        },
      ],
      options: [
        {
          value: "ty",
          label: "typecho",
        },
        {
          value: "wp",
          label: "worldpress",
        },
        {
          value: "zblog",
          label: "zblog",
        },
        {
          value: "emlog",
          label: "emlog",
        },
        {
          value: "eyou",
          label: "eyou",
        },
        {
          value: "diguo",
          label: "帝国",
        },
        {
          value: "mfcw",
          label: "魔方财务",
        },
      ],
      blog_img_type: [
        {
          value: "img_type1",
          label: "每段都添加一张图片",
        },
        {
          value: "img_type2",
          label: "每段前面添加一张图片",
        },
        {
          value: "img_type3",
          label: "每篇文章开头添加一张图片",
        },
        {
          value: "img_type4",
          label: "每篇文章最后添加一张图片",
        },
        {
          value: "img_type5",
          label: "不需要添加图片",
        },
      ],
      log_Status_list: [
        {
          value: true,
          label: "直接发布",
        },
        {
          value: false,
          label: "保存到草稿",
        },
      ],
      blog_img: "img_type1",
    };
  },
  methods: {
    send () {
      alert("提交成功");
      const token = localStorage.getItem("token");
      const data = {
        blog_type: this.blog_type,
        data_nbsb: this.data_nbsb,
        data_user: this.data_user,
        data_host: this.data_host,
        data_prot: this.data_prot,
        data_pass: this.data_pass,
        log_Status: this.log_Status,
        send_api: this.send_api,
        token: token,
        count: this.count,
        fl_id: this.fl_id,
        tag_id: this.tag_id,
        def_time: this.def_time,
        blog_img: this.blog_img,
        theme: this.theme,
        random_or_no: this.random_yes,
        keys: this.key,
        url: this.url,
      },
        headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        };

      axios
        .post(GPTAPI + "/add_test", data, headers)
        .then((response) => {
          alert(response.data);
          console.log(response.data);
          // this.$message({
          //   showClose: true,
          //   message: response.data,
          //   type: 'success'
          // });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    test_conn () {
      const data = {
        data_nbsb: this.data_nbsb,
        data_user: this.data_user,
        data_host: this.data_host,
        data_pass: this.data_pass,
        data_prot: this.data_prot,
      },
        headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        };
      axios
        .post(GPTAPI + "/test_db_connection", data, headers)
        .then((response) => {
          // alert()
          this.$message({
            showClose: true,
            message: response.data["status"],
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_tag () {
      const data = {
        blog_type: this.blog_type,
        data_nbsb: this.data_nbsb,
        data_user: this.data_user,
        data_host: this.data_host,
        data_pass: this.data_pass,
        data_prot: this.data_prot,
      },
        headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        };
      axios
        .post(GPTAPI + "/get_tag", data, headers)
        .then((response) => {
          var iidd = "";
          if (
            response.data ==
            "查询错误，请检查博客系统填写是否正确或数据库名是否正确！"
          ) {
            alert(response.data);
            return;
          }
          for (var i = 0; i < response.data.length; i++) {
            var first = response.data[i][0];
            var second = response.data[i][1];
            // 在这里进行对列表的操作
            iidd = iidd + "【" + second + "：" + "id=" + first + "\n】";
          }
          this.$confirm(iidd, "提示：自行区分是分类还是标签", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
          })
            .then(() => { })
            .catch(() => { });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted () {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("未登陆");
      window.location.href = window.location.origin + "/#/";
    }
    // 获取缓存的记录值
    const cachedDataNbsb = window.localStorage.getItem("data_nbsb");
    const cachedDataUser = window.localStorage.getItem("data_user");
    const cachedDataPass = window.localStorage.getItem("data_pass");
    const cachedDataHost = window.localStorage.getItem("data_host");
    const cachedDataProt = window.localStorage.getItem("data_prot");
    const cachedBlogType = window.localStorage.getItem("blog_type");
    const cachedLogStatus = window.localStorage.getItem("log_Status");
    const random_yes_NB = window.localStorage.getItem("random_yes");
    const send_api = window.localStorage.getItem("send_api");

    // 根据缓存值和默认值来设置数据
    this.data_nbsb = cachedDataNbsb === null ? "blog" : cachedDataNbsb;
    this.data_user = cachedDataUser === null ? "root" : cachedDataUser;
    this.data_pass = cachedDataPass === null ? "123456" : cachedDataPass;
    this.data_host = cachedDataHost === null ? "localhost" : cachedDataHost;
    this.data_prot = cachedDataProt === null ? "3306" : cachedDataProt;
    this.blog_type = cachedBlogType === null ? "ty" : cachedBlogType;
    this.log_Status =
      cachedLogStatus === null ? true : JSON.parse(cachedLogStatus);
    this.random_yes = random_yes_NB === null ? "yes" : random_yes_NB;
    this.send_api = send_api === null ? null : send_api;

    // 监听数据变化，保存至 localStorage
    this.$watch(
      () =>
        JSON.stringify({
          data_nbsb: this.data_nbsb,
          data_user: this.data_user,
          data_pass: this.data_pass,
          data_host: this.data_host,
          data_prot: this.data_prot,
          log_Status: this.log_Status,
          blog_type: this.blog_type,
          random_yes: this.random_yes,
          send_api: this.send_api,
        }),
      () => {
        window.localStorage.setItem("data_nbsb", this.data_nbsb);
        window.localStorage.setItem("data_user", this.data_user);
        window.localStorage.setItem("data_pass", this.data_pass);
        window.localStorage.setItem("data_host", this.data_host);
        window.localStorage.setItem("data_prot", this.data_prot);
        window.localStorage.setItem("blog_type", this.blog_type);
        window.localStorage.setItem("random_yes", this.random_yes);
        window.localStorage.setItem(
          "log_Status",
          JSON.stringify(this.log_Status)
        );
        window.localStorage.setItem("send_api", this.send_api);
      }
    );
  },
};
</script>

<style>
body {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}
</style>
