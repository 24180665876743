<template>
    <div class="StartLogin" style="background-color:#EBEEF2;" >
            <div  id="app" style="display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; " >
                <div class="content" style="display:block;">
                    <div class="login-wrapper">
                        <h1>GPT AUTO Article </h1>
                        <h2>GPT自动化撰写发布 | 智能创作 | SEO排名</h2>
                        <h2>欢迎登陆，今天是个不错的日子</h2>
                        
                        <div class="login-form">
                            <div class="username">
                                <el-input
                                                    placeholder="请输入用户名或邮箱"
                                                    v-model="username"
                                                    clearable>
                                                    </el-input>
                            </div>
                            <div class="password">
                                <el-input placeholder="请输入密码" v-model="password" show-password></el-input>
                            </div>
                            <div @click="forget()">忘记密码</div>
                            <br>
                            <div class="login-btn" @click="login()">登 录</div>
                            <h4>作者QQ:3500079813</h4>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import{ GPTAPI} from "@/config";
// var { expressjwt:jwt } = require("express-jwt")
const axios = require('axios');
    export default({
            el: '#app',
            data() {
                return {
                    GPTAPI:GPTAPI,
                    isAuthenticated: false,
                    username: '',
                    password: '',
                    email:'',
                    auth_code: "",
                    visible:false,
                    newpassword: '',
                    form:{
                            email:undefined,
                            auth_code:undefined,
                            password:undefined,
                            new_password:undefined
                        }
                    }
            },
            methods: {
                forget(){
                     this.$message({
                        message: '请联系作者获取账号 , 非诚勿扰',
                        type: 'warning'
                        });
                },
                login() {
                    const data = {
                        username: this.username,
                        password: this.password
                    }
                    axios.post("http://43.129.190.146:8901/login", data)
                        .then(response => {
                            const { code, data: { token } } = response.data
                            if (code === 200 && token) {
                                localStorage.setItem('token', token)
                                 window.location.href ='./#/NbSb';
                                 this.$message({
                                    showClose: true,
                                    message: '登陆成功',
                                    type: 'success'
                                    });
                            }
                            else {
                                (this.$message({
                                    message: '账号或密码错误！',
                                    type: 'error'
                                  }))
                                localStorage.setItem('token', "")
                            }
                        })
                }
            },
        
        })
        
</script>

<style scoped>

.StartLogin{
    background-color:#0e71f1;

}
.content{
    background-color: #fff;
border-radius: 5px ;
}

.login-form{
    margin: 30px;
}

.divider{
    border-radius: 5px;
    font-size: 12px;
    margin-bottom: 20px;
    color: #4C78DD;
}

/*///////////////////////*/
.content{
        padding: 30px;

        width: 80%;
    }
    h2{
    font-weight: 300;
    color:#a8b0bb ;
}   
h1{
    font-size: 20px;
}
h2{
    font-size: 14px;
}
p{
    font-size: 12px;
}
.username{
    margin: 10px 0;
}
.password{
    margin: 10px 0;
}
.login-wrapper h1,.login-wrapper h2{
    margin: 10px ;
    margin-left: 30px ;
}
.login-btn{
    width: 20%;
    background-color: #D2DDF7;
    text-align:center;
    font-size: 15px;
    font-weight: 600;
    padding: 5px;
    border-radius: 5px ;

}
/*<!-- /////////////////////// --> */

@media (min-width:1000px) {
    .content{
        padding: 100px;
        padding-top: 0;
        padding-bottom: 120px;

        width: 50%;
    }
    .username{
    margin: 30px 0;
}
.password{
    margin: 30px 0;
}

    h2{
    font-weight: 300;
    color:#a8b0bb ;
}
    .login-wrapper h1,.login-wrapper h2{
    margin: 30px ;
    }
}
@media (max-width:740px) {
    .content{
        padding: 30px;
        height: 40%;
        width: 80%;
    }
    h2{
    font-weight: 300;
    color:#a8b0bb ;
}   
h1{
    font-size: 20px;
}
h2{
    font-size: 14px;
}
p{
    font-size: 12px;
}
.username{
    margin: 10px 0;
}
.password{
    margin: 10px 0;
}
.login-wrapper h1,.login-wrapper h2{
    margin: 10px ;
    margin-left: 30px ;
}
.login-btn{
    width: 100%;
    background-color: #D2DDF7;
    text-align:center;
    font-size: 15px;
    font-weight: 600;
    padding: 5px;
    border-radius: 5px ;

}
}

</style>
<style>
@media (max-width: 768px) {
  .el-message-box {
    width: 300px;
  }
}
</style>