
// export default [
//     { path: '/', redirect: StartLogin },
//     {path:'/NbSb',comments:NbSb}
// ]
import Vue from "vue";
import  Route  from "vue-router";
import StartLogin from "@/components/StartLogin"
import NbSb from "@/components/NbSb"
import IndexAi from "@/components/IndexAi"

Vue.use(Route);

export default new Route({
    // mode: 'history',
    routes:[
        {
            path:'/',
            name:"IndexAi",
            component: IndexAi
        },
        {
            path:'/StartLogin',
            name:"StartLogin",
            component: StartLogin
        },
        {
            path:'/NbSb',
            name:"NbSb",
            component: NbSb
        },
        {
            path:'/IndexAi',
            name:"IndexAi",
            component: IndexAi
        },
    ]
})